import './Regions.css'

function Black(props) {
  return (
    <div className="Black">
      {props.children}
    </div>
  );
}

export default Black;