import { FaCaretDown } from "react-icons/fa";
import React, { useRef, useEffect, useState } from 'react';

import './Spacer.css'

function Spacer(props) {
  const [clicked, setClicked] = useState(false);
  const elementRef = useRef()
  
  const onClick = (event) => {
    setClicked(!clicked);
  };
  window.addEventListener("scroll", (event) => {
    if (elementRef.current) {
      const rect = elementRef.current.getBoundingClientRect();
      const y_mid = window.innerHeight/2.5;
      const y = rect.top - y_mid;
      
      // only change if spacer is significantly of center 
      if(Math.abs(y) > y_mid/8)
        // doing extra checks since react will rerender the entire element when state changes
        if(y > 0 && clicked) setClicked(false);
        else if(y < 0 && !clicked) setClicked(true);
    }
  });
  return (
    <a ref={elementRef} draggable={false} href={clicked?props.next:props.prev} className="Spacer" id={props.id} onClick={onClick} clicked={clicked?"true":"false"}>
      <div>
        <FaCaretDown size="4rem"/>
      </div>
    </a>
  );
}

function ShadowSpacer(props) {
  return (
    <a draggable={false} className="Spacer" id={props.id}>
      <div>
      </div>
    </a>
  );
}

export { Spacer, ShadowSpacer };