import './App.css';
// fonts (too much css to be included in fonts.css)
import './global/Poppins.css';
// general fonts
import './global/Fonts.css';

// global css styling
import './global/global.css';
import './global/additional.css';

import Home from './pages/Home/Home.jsx';

import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Topbar from './components/Topbar/Topbar.jsx';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Topbar/>
        <div style={{marginTop:"7em", display: "grid"}}>
          <Routes>
            <Route path="" element={<Home />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;