import { FaArrowRight, FaCaretUp, FaChevronUp } from "react-icons/fa";
import { IoIosMore } from "react-icons/io";
import React, { useRef, useEffect, useState } from 'react';

import './Projects.css';

function throttleEvent(func, delay) {
  let timeout=null
  return (event) => {
  	event.preventDefault();
	event.stopPropagation();
	if(!timeout) {
   	  func(event)
	  timeout=setTimeout(() => {
		timeout=null
	  }, delay)
	}
  }
}


function Project(props) {
  return (
    <div className="Project" show={props.show} prev={props.prev} next={props.next} onClick={props.onClick} style={props.onClick?{cursor: "pointer"}:{}}>
      <div className="container-icon">
        <img draggable="false" alt="404" src={process.env.PUBLIC_URL+"img/medal.png"}/>
      </div>
      <div className="container-description">
        <h1 className="prevent-select">{props.project.name}</h1>
        <p className="prevent-select">{props.project.description}</p>
      </div>
      <a href={props.project.link}>
        <FaArrowRight size="4rem"/>
      </a>
    </div>
    );
}

function Prev(props) {
	return (
		<div className="Project-Preview" onClick={props.onClick}>
			<h3 className="prevent-select">{props.project.name}</h3>
		</div>
		);
}
function Next(props) {
	return (
		<div className="Project-Next" onClick={props.onClick}>
			<h3 className="prevent-select">{props.project.name}</h3>
		</div>
		);
}

function Projects() {
  const projects = [
      {
        id: 0,
        name: "Hubhupper.app",
        description: "Ridesharing with sustainability in mind",
        link: "https://github.com/TeamSmil3y/hackaburg_backend",
      },
      {
        id: 1,
        name: "Pigeon",
        description: "Python web framework",
        link: "https://github.com/TeamSmil3y/PigeonPost",
      },
      {
        id: 2,
        name: "WePlan",
        description: "Smart planning tool for construction",
        link: "https://github.com/lstuma/WePlan",
      },
      {
        id: 3,
        name: "Paper2Box",
        description: "AI-powered UML-Diagram digitalization",
        link: "https://github.com/lstuma/Paper2Box",
      },
    ];

  const [project, _setProject] = useState(1);
  const projectRef = useRef(project);
  projectRef.current = project;

  const getProject = (id) => {
  	if(id < 0) return projects.length+id
  	return id%projects.length
  }

  const nextProject = () => {
	setProject(getProject(projectRef.current+1));
  }
  const prevProject = () => {
	setProject(getProject(projectRef.current-1));
  }
  const setProject = (value) => {	
  	projectRef.current = value;
	_setProject(projectRef.current);
  }
  const onScroll = (event) => {
	  if(event.wheelDeltaY>0) { 
	  	prevProject();
	  }
	  else if(event.wheelDeltaY<0) {
	  	nextProject();
	  }
    }

  const scroller = useRef();
  useEffect(() => {
        if (scroller && scroller.current) {
        	let throttledOnScroll = throttleEvent(onScroll, 500);
            scroller.current.addEventListener("wheel", throttledOnScroll, false);
            return function cleanup() {
                 scroller.current.removeEventListener("wheel", throttledOnScroll, false);
            };
        }
  }, []);

  return (
    <div ref={scroller} className="Projects" id="projects">
    	{
    		projects.map(
    			(item) => {
		    		let is_prev = item.id!=project;
	    			let onClick = is_prev?() => {setProject(item.id)}:undefined;
	    			return (
				    	<Project prev={is_prev?"true":"false"} project={item} onClick={onClick} key={item.id}/>
			    	);
	    		}
	    	)
		}
    </div>
  );
}

export default Projects;