import './Topbar.css'

function Topbar() {
  return (
    <div className="Topbar" id="topbar">
      <div className="element">
        <h1 className="topbar-brand prevent-select">teamsmiley.org</h1>
      </div>
    </div>
  );
}

export default Topbar;