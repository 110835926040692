import { FiAtSign } from "react-icons/fi";
import { IoLink } from "react-icons/io5";

import './Home.css'
  
import Black from '../../components/Regions/Black.jsx';
import { Spacer, ShadowSpacer } from '../../components/Spacer/Spacer.jsx';
import { ImgGrid, Grid } from '../../components/Grids/Grids.jsx';
import Projects from './Projects.jsx';

function MemberCard(props) {
  return(
      <div className="MemberCard">
        <div className="container">
          <div className="container-icon">
            <FiAtSign size="2rem" style={{strokeWidth: ".18rem"}}/>
          </div>
          <h1 className="name prevent-select">{props.name}</h1>
        </div>
        <a href={props.link}>
          <div className="container-icon">
            <IoLink size="2.5rem" style={{strokeWidth: ".18rem"}}/>
          </div>
        </a>
      </div>
    )
}

function Home() {
  return (
    <div className="Home" id="home">
      <Grid>
        <div className="container-logo">
          <img draggable="false" alt="404" src="https://github.com/lstuma/lstuma/raw/main/teamsmiley.png?raw=true"/>
        </div>
      </Grid>

      <Black>

        <Spacer prev="#topbar" next="#about" id="about"/>

        <ImgGrid direction="left" src={process.env.PUBLIC_URL+"img/astro_with_star.png"}>
          <h1 className="prevent-select">
              About TeamSmiley 
          </h1>
          <p>
            TeamSmiley is a group of tech enthusiast and hackathonists interested
            in a diverse array of technological fields including Machine Learning, Artificial
            Intelligence, Web technologies, Cybersecurity and more.
          </p>
        </ImgGrid>

        <Spacer prev="#about" next="#what-weve-worked-on" id="what-weve-worked-on"/>

        <ImgGrid direction="right" src={process.env.PUBLIC_URL+"img/cursor.png"}>
          <h1 className="prevent-select">
              Some Of Our Projects 
          </h1>
          <div className="container-projects">
            <Projects />
          </div>
        </ImgGrid>

        <Spacer prev="#what-weve-worked-on" next="#meet-the-team" id="meet-the-team"/>

        <Grid>
          <div className="container-paragraph">
            <h1 className="prevent-select">
              <span className="lt"> &#60; </span> 
                Meet The Team
              <span className="gt"> &#62; </span>
           </h1>
           <div className="container-membercards">
             <MemberCard name="lstuma" link="https://lstuma.dev"/>
             <MemberCard name="raspitim" link="https://github.com/raspitim"/>
             <MemberCard name="olivia" link="https://www.linkedin.com/in/olivia-zens/"/>
           </div>
         </div>
        </Grid>
        <ShadowSpacer/>
      </Black>

    </div>
  );
}

export default Home;
