import './ImgGrid.css'
import './Grid.css'

function ImgGrid(props) {
  return (
    <div className={props.direction=="left"?"grid-l-img-r-section":"grid-r-img-l-section"} id={props.id}>
      {props.direction=="left"?<img draggable="false" alt="404" src={props.src}/>:<></>}
      <div>
        {props.children}
      </div>
      {props.direction=="right"?<img draggable="false" alt="404" src={props.src}/>:<></>}
    </div>
  );
}

function Grid(props) {
  return (
    <div className="grid-section" id={props.id}>
      {props.children}
    </div>
  );
}

export {ImgGrid, Grid};